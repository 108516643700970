import React from "react"
import Header from '../../layouts/header'
import Canvas from '../../layouts/canvas'
import Footer from '../../layouts/footer'
import Breadcrumb from '../../layouts/breadcrumb'
import { navigate, graphql } from "gatsby"
import {CircleSpinner} from "react-spinners-kit"
import {Helmet} from 'react-helmet'
import {Link} from 'gatsby'






class WorksPage extends React.Component {
  constructor(props) {
    super(props)

    this.state={
      name: '',
      nameerror: '',
      email:'',

      message: '',
      messageerror:'',
      emailvalid: false,
      emailerror:'',
      buttondisabled:true,
      success:false,
      loading:false,
      errorsend: ''
    }
  }

  handleChange = (e) => {
    var $ = window.$
    var pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
      if(e.target.name !== 'email' && e.target.value) {
        $(`.${e.target.name}`).removeClass('notvalid')
        this.setState({
          [`${e.target.name}error`]: ""
        })
      }
      if(e.target.name === 'email' && e.target.value.match(pattern)) {
        $(`.${e.target.name}`).removeClass('notvalid')
        this.setState({
          [`${e.target.name}error`]: ""
        })
      }

      this.setState({
        [e.target.name] : e.target.value,
        buttondisabled: true
      })


      setTimeout(() => {
        if(this.state.name  && this.state.email.match(pattern) && this.state.message) {


          this.setState({
            buttondisabled:false

          })
        }
      },500)



  }

  handleBlur = (e) => {
    var $ = window.$
    if(e.target.name === 'email') {
      var pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;


      if(!e.target.value.match(pattern)) {
         $('.email').addClass('notvalid')
         this.setState({
           emailerror:"Το email είναι υποχρεωτικό"
         })

      }
      else {
        $('.email').removeClass('notvalid')
        this.setState({
          emailvalid: true,
          emailerror:''
        })

      }
    }
    else {
      if(!e.target.value) {
        $(`.${e.target.name}`).addClass('notvalid')
        this.setState({
          [`${e.target.name}error`]: `Το ${e.target.placeholder} είναι υποχρεωτικό `
        })
      }
      else {
         $(`.${e.target.name}`).removeClass('notvalid')
         this.setState({
           [`${e.target.name}error`]: ""
         })
      }
    }

  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({loading:true})
     var baseurl = this.props.data.allSitePlugin.edges[0].node.pluginOptions.url
    var data = {
                 "webform_id":"contact",

                                "webform_data" : {
                                                "name" : this.state.name,
                                                "email" : this.state.email,
                                                "message" : this.state.message
                                }



}
    var url = `${baseurl}/wd_webform/submit`
     fetch(url, {
      method:'post',
      mode: 'cors',
      // headers: {
      //      'Content-Type': 'application/json',
      //      // 'Content-Type': 'application/x-www-form-urlencoded',
      //  },
      body:JSON.stringify(data)
    })
    .then(function(response) {


    return response.json();


})
    .then(res => {
      console.log('message sending in fetch')
      window.gtag("event", "contact_form_submit")
      this.setState({
        name: '',
        nameerror: '',
        email:'',

        message: '',
        messageerror:'',
        emailvalid: false,
        emailerror:'',
        buttondisabled:true,
        success:true,
        loading:false
      })
      setTimeout(() => {
        navigate('/')
      }, 1500)
    })
    .catch((error) => {
           console.log(error)
           window.gtag("event", "contact_form_fail")
        this.setState({
          loading:false,
          errorsend:'Network Error'
        })
        setTimeout(() => {
          navigate('/')
        }, 1500)
    })


  }







  componentWillUnmount() {
    console.log('WorksPage Unmounting')
    // document.getElementById("mainscript").remove();
  }

  componentDidUpdate() {

  }
  render() {
    var loc = [];//TODO pernaei san property sto breadcrumb, prepei na ginei function kai na to kano import giati einai idio se ola ta components
    var breadpath = this.props.location.pathname.split(/[/]/)

    breadpath.shift()
    breadpath.forEach((item,i) => {
      var value = null;
      var path = null;
      // console.log(item,i,loc[0])

      if(i === 0) {
        if(item === 'en' || item === 'el') {
          value = 'HOME';
          path=`/${item}`
        }
        else {
          value=item;
          path=`/${item}`
        }
        loc.push({value: value, path: path})
      }
      else {
        value=item;
        path=`${loc[i-1].path}/${item}`
        loc.push({value: value, path: path})
      }



    })

    return (

      <Canvas>
        <Helmet>
         {this.props.data.drupal.metainfo.entities[0].entityMetatags.map((item,i) => {
           if(item.key === 'title') {
             return (
                 [<title key={i}>{`Επιλεγμενα εργα μας | WebDimension`}</title>,<meta property="og:title" content={`Επιλεγμενα εργα μας | WebDimension`} />]
             )
           }

           else if(item.key === 'description') {
             return (
                [<meta key={i} name="description" content={item.value}/>, <meta property="og:description" content={item.value} />]
             )
           }

           else {
             return null;
           }
         })}
         <link  rel="canonical" href={process.env.FRONT_URL+this.props.path} />
         <meta property="og:type" content="Website" />
         <meta property="og:url" content={`${process.env.FRONT_URL}${this.props.path}`} />
         <meta property="og:image" content={`${process.env.FRONT_URL}/wd_logo.png`} />
         <meta property="og:image:width" content="200" />
         <meta property="og:image:height" content="200" />
        </Helmet>

          <Header 
            menu={this.props.data.drupal.menuel} 
            pathname="/works" 
            language="EL" 
            langpath={[{url:'/en/works', id:'en'}, {url:'/el/works', id:'el'}]}
            />
          <Breadcrumb title="ΕΠΙΛΕΓΜΕΝΑ ΕΡΓΑ ΜΑΣ" path={loc}/>

      

            {/***  lavie ***/}
            <section className={`row py-5 position-relative my-0 sectionContainer`}>

                <div className='d-none d-lg-block'>
                    {/* background image */}
                    <img src="/new/works_page/lavie/background-lavie.jpg"
                        alt="lavie bg"
                        style={{
                            objectFit: "cover",
                            height: '70%',
                            position: 'absolute',
                            width: '100%',
                            left: '0',
                            top: '15%',
                            right: '0',
                            color: 'transparent',
                        }}
                        />
                </div>


                <div className='d-block d-lg-none'>
                   
                    <img 
                         src="/new/works_page/lavie/lavie-background-mobile.jpg"
                         alt="lavie bg"
                         style={{
                            objectFit: "cover",
                            objectPosition: 'top left',
                            position: 'absolute',
                            width: '100%',
                            left: '0',
                            top: '150px',
                            right: '0',
                            bottom: '0',
                            color: 'transparent',
                        }}
                    />
                </div>

                <div className='col-12 col-lg-6 order-2 order-lg-1  d-flex justify-content-center align-items-center'>

                    <div className="position_relative">

                        <div className={`bgText mobileExtraBottomPadding`}>
                            <img src='/new/works_page/lavie/lavie-logo.png' alt='lavie logo' className="img-fluid"/>

                            <div>
                                <p className={`magentoShop`}>Magento Eshop</p>

                                <p className={`paragraph`}>Η ραγδαία ανάπτυξη επιχείρησης "La vie en Rose by Dimitra Katsafadou" στον ψηφιακό χώρο αποτέλεσε μια πρόκληση όσον αφορά τη διαχείριση του μεγάλου όγκου των ψηφιακών επισκεπτών. Για την αντιμετώπιση αυτού του ζητήματος, αποφασίσαμε να εφαρμόσουμε μια συνδυασμένη τεχνολογική προσέγγιση.  </p>

                                <p className={`paragraph`}>Χρησιμοποιήσαμε React για το front end, ενώ το Magento υποστηρίζει το backend του e-shop, παρέχοντας όλες τις απαραίτητες λειτουργίες για μια ολοκληρωμένη ψηφιακή αγορά. Τέλος, για την ομαλή και γρήγορη διαδικασία φόρτωσης των σελίδων, καθώς και για την αποτελεσματική διαχείριση των δεδομένων σε πραγματικό χρόνο, ενσωματώσαμε το Redis στην αρχιτεκτονική μας.
                                </p>

                                <p className={`paragraph`}>
                                Μέσω αυτής της τεχνολογικής προσέγγισης, καταφέραμε να υποστηρίξουμε το μεγάλο όγκο της κίνησης, διασφαλίζοντας την ανταπόκριση και την απόδοση της πλατφόρμας σε όλες τις συνθήκες.
                                </p>

                                <button data-btn={'lavie'} className={`btn`}>
                                  <Link to={'/el/node/120'}>
                                    Δείτε το case study
                                  </Link>
                                  </button>
                            </div>

                            <div className={`lavieItemDesktop`}>
                                <img src='/new/works_page/lavie/lavie_item.png'
                                    alt='lavie item'
                                    width='256px'
                                    height='292px'
                                />
                            </div>

                            <div className={`lavieItemMobile`}>
                                <img src='/new/works_page/lavie/lavie_item.png'
                                    alt='lavie item'
                                    width='128px'
                                    height='146px'
                                />
                            </div>



                        </div>
                    </div>

                </div>


                <div className="col-12 col-lg-6 order-1 order-lg-2">
                    <div className="d-flex justify-content-center align-items-center">
                        <div className={`lavieDesktopImage`}>
                            <img 
                                src='/new/works_page/lavie/Laptop-lavie.png'
                                alt='lavie background image'
                                width={660} 
                                height={400}  
                            />
                        </div>
                    </div>
                </div>

            </section> 


            {/*** Anthemion  ***/}
            <section className={`row py-5 sectionContainer position-relative`}>

                    <div className={`anthemionBgColor`} />

                    <div className="col-12 col-lg-6">
                        <div className="d-flex justify-content-center align-items-center">
                            <img 
                            src='/new/works_page/anthemion/deskop_anthemion.png'
                            alt='anthemion background image'
                            width={`959px`}
                            height={`562px`}
                            />
                        </div>
                    </div>


                    <div className='col-12 col-lg-6 d-flex justify-content-center align-items-center'>

                        <div className="position_relative">

                            <div className={`anthemion1 d-none d-md-block`}>
                            <img 
                                src='/new/works_page/anthemion/flower-1.png'
                                alt='anthemion flower-1'
                                width={`450px`}
                                height={`456px`}
                            />
                            </div>

                            <div className={`anthemion2`}>
                                <img 
                                    src='/new/works_page/anthemion/flower-2.png'
                                    alt='anthemion flower-2'
                                    width={`450px`}
                                    height={`428px`}
                                />
                            </div>


                            <div className={`bgText`}>
                                <img 
                                    src='/new/works_page/anthemion/anthemion_logo .png'
                                    alt='anthemion logo'
                                    className="img-fluid"
                                />
                                <div>
                                    <p className={`magentoShop`}>Magento Eshop</p>

                                    <p className={`paragraph`}>
                                        Το μεγαλύτερο online δίκτυο ανθοπωλείων στην Ελλάδα, με εκατοντάδες συνεργασίες ανθοπωλείων / κατασημάτων από όλο τον κόσμο.
                                    </p>

                                    <p className={`paragraph`}>
                                        Μας εμπιστεύθηκε για την κατασκευή του e-shop του και το τελικό αποτέλεμα ήταν πέρα από κάθε προσδοκία!
                                    </p>

                                    <p className={`paragraph`}>
                                        Για την κατασκεύυή του ηλεκτρονικού καταστήματος anthemionflowers.com χρησιμοποιήσαμε την καλύτερη πλατφόρμα e-commerce, το Magento 2. Είναι από τα πιο δημοφιλή e-commerce συστήματα με βασικά χαρακτηριστικά του την ασφάλεια, ταχύτητα, ευελιξία, αξιοπιστία.
                                    </p>

                                    <button data-btn={'anthemion'} className={`btn`}>
                                    <Link to={'/el/node/112/'}>
                                    Δείτε το case study
                                    </Link>
                                    </button>
                                </div>

                            </div>
                        </div>

                    </div>

            </section>

            
         
            {/*** StyduTours ***/}
            <section className={`row py-5 position-relative sectionContainer position-relative`}>
                <div>
        
                    <img 
                     src="/new/works_page/studytours/studytours_bg.png"
                     alt="study tours bg"
                     style={{
                         objectFit: "cover",
                         height: '70%',
                         position: 'absolute',
                         width: '100%',
                         left: '0',
                         top: '15%',
                         right: '0',
                         bottom: '0',
                         color: 'transparent',

                     }}
                    />
                </div>

                <div className='col-12 col-lg-6 order-2 order-lg-1  d-flex justify-content-center align-items-center'>

                    <div className="position_relative">

                        <div className={`studytours1`}>
                            <img 
                            src='/new/works_page/studytours/studytours_item.png'
                            alt='studytours aeroplane'
                            width={`400px`}
                            height={`267px`}
                            />
                        </div>


                        <div className={`bgText`}>

                            <img 
                                src='/new/works_page/studytours/studytours_logo.png'
                                alt='studytours logo'
                                className="img-fluid"
                            />

                            <div>
                                <p className={`magentoShop`}>
                                    Drupal WebShop
                                </p>

                                <p className={`paragraph`}>
                                To Stydy Tours από το 1964 δραστηριοποιείται στον χώρο του εκπαιδευτικού τουρισμού και ταξιδιών προσφέροντος ολοκληρωμένες εκπαιδευτικές προτάσεις σε μαθητές, υποψήφιους φοιτητές και επαγγεμλατίες.
                                </p>

                                <p className={`paragraph`}>
                                Για την δημιουργία του ηλεκτρονικού καταστήματος, χρησιμοποιήσαμε πρωτοποριακές τεχνολογίες για να προσφέρουμε μια μοναδική και αρτιότατη εμπειρία πλοήγησης. Επιλέξαμε το Drupal για τη διαχείριση του περιεχομένου, χάρη στις ανώτερες δυνατότητές του, και την React για να δώσουμε ζωντάνια στο frontend. Η συνεργασία αυτών των δύο τεχνολογιών μας έδωσε τη δυνατότητα να φέρουμε στο προσκήνιο ένα ηλεκτρονικό κατάστημα που συνδυάζει ταχύτητα, προσαρμοστικότητα και αξιοπιστία στο μέγιστο βαθμό."
                                </p>


                                <button data-btn={'studytours'} className={`btn`}>
                                  <Link to={`/el/works/seo-studytours`}>
                                  Δείτε το case study
                                  </Link>
                                  </button>
                            </div>

                        </div>
                    </div>

                </div>


                <div className="col-12 col-lg-6 order-1 order-lg-2">
                    <div className="d-flex justify-content-center align-items-center">
                        <div>
                            <img 
                            src='/new/works_page/studytours/studytours_desktop.png'
                            alt='studytours desktop image'
                            width={`959px`}
                            height={`679px`}
                            />
                        </div>
                    </div>
                </div>

            </section>


            {/*** Ahmad Tea ***/}
            <section className={`row py-5 position-relative sectionContainer position-relative`}>

            <div className={`anthemionBgColor`} />

            <div className="col-12 col-lg-6">
                <div className="d-flex justify-content-center align-items-center">
                    

                    <img 
                    src='/new/works_page/ahmadtea/ahmadtea-desktop.png'
                    alt='ahmadtea desktop image'
                    width={`959px`}
                    height={`600px`}
                    />
                </div>
            </div>


            <div className='col-12 col-lg-6 d-flex justify-content-center align-items-center'>

                <div className="position_relative">

                    <div className={`ahmadtea1`}>
                        <img 
                        src='/new/works_page/ahmadtea/ahmadtea-item-1.png'
                        alt='ahmadtea-item-1'
                        width={`368px`}
                        height={`197px`}
                        />
                    </div>

                    <div className={`ahmadtea2`}>
                       

                        <img 
                          src='/new/works_page/ahmadtea/ahmadtea-item-1.png'
                          alt='ahmadtea-item-1'
                          width={`368px`}
                          height={`197px`}
                        />
                    </div>

                    <div className={`ahmadtea3`}>
                        

                        <img 
                        src='/new/works_page/ahmadtea/ahmadtea-item-2.png'
                        alt='ahmadtea-item-2'
                          width={`400px`}
                        height={`421px`}
                        />
                    </div>


                    <div className={`bgText`}>
                        

                        <img 
                         src='/new/works_page/ahmadtea/Ahmad-Tea-Logo-300px.png'
                         alt='Ahmad-Tea-Logo logo'
                         className="img-fuild"
                        />

                        <div>
                            <p className={`magentoShop`}>Magento Eshop</p>

                            <p className={`paragraph`}>
                                Η εταιρεία Ahmad Tea είναι μια οικογένειακή εποχείρηση με βάση το Ηνωμένο Βασίλειο. Ιδρύθηκε με πάθος για καλύτερο τσάι και έπειτα από τέσσερις γενιές εμπειρίας, συνεχίζει την εξειρετική πορεία της, εξασφαλίζοντας ότι κάθε φλιτζάνι που φέρει το όνομα της ανταποκρίνεται στις υψηλές προσδοκίες των πελατών της.
                            </p>

                            <p className={`paragraph`}>
                                Η Ahmad Tea πλέον ανήκει στις 5 πρώτες εταιρίες τσαγιού με τις περισσότερες πωλήσεις παγκοσμίως, με παρουσία σε πάνω από 80 χώρες. Παράλληλα, συνεχίζει να αναπτύσεται ως μια οικογενειακή επιχείρηση, επεκτείνοντας την φιλανθρωπική της δράση και διατηρώντας σταθερή την υψηλή ποιότητα των προϊόντων της σε όλο τον κόσμο.
                            </p>

                            <button data-btn={'ahmadtea'} className={`btn`}>
                              <Link to={'/el/node/97'}>
                              Δείτε το case study
                              </Link>
                            </button>
                        </div>

                    </div>
                </div>

            </div>

            </section>


            {/*** Doctor Anytime  ***/}
            <section className={`row py-5 position-relative mb-5 sectionContainer`}>

                <div>
                 
                    <img 
                     src='/new/works_page/doctor_assistant/doctor_assistant_bg_image.jpg'
                     alt='doctor assistant bg'
                     style={{
                         objectFit: 'cover',
                         height: '70%',
                         transform: 'rotate(180deg)',
                         position: 'absolute',
                         width: '100%',
                         left: '0',
                         top: '15%',
                         right: '0',
                         bottom: '0',
                         color: 'transparent',
                     }}
                    />
                </div>

                <div className='col-12 col-lg-6 order-2 order-lg-1 d-flex justify-content-center align-items-center'>

                    <div className="position_relative">
                        <div className={`bgText`}>

                          
                            <img 
                             src='/new/works_page/doctor_assistant/logod.png'
                             alt='Ahmad Tea logo'
                             className="img-fuild"
                            />

                            <div>
                                <p className={`magentoShop`}>
                                    Web App
                                </p>

                                <p className={`paragraph`}>
                                    Online Εφαρμογή ηλεκτρονικών ραντεβού, διαχείριση ασθενών, ιατρικός φάκελος και ενσωμάτωση της εφαρμογής στην ιστοσελίδα.
                                </p>

                                <p className={`paragraph`}>
                                    Εύκολο Σύστημα ηλεκτρονικών Ραντεβού, με αυτόματη ενημέρωση της εφαρμογής διαχείρησης για κάθε νέο ραντεβού. Στοιχεία επικοινωνίας ασθενή, ιατρικό ιστρορικό και συνοπτικό ιστορικό των ραντεβού του. Ευέλικτη φόρμα αναζήτησης ασθενών και πλήθος επιπλέον λειτουργιών απαραίτητες για ένα ιατρείο.
                                </p>

                                <button data-btn={'doctor'} className={`btn`}>
                                  <Link to={`/el/works/docassistant`}>
                                  Δείτε το case study
                                  </Link>
                                </button>
                            </div>

                        </div>
                    </div>

                </div>

                <div className="col-12 col-lg-6 order-1 order-lg-2">
                    <div className="d-flex justify-content-center align-items-center">
                        <img 
                        src='/new/works_page/doctor_assistant/doctor_assistant_desktop.png'
                        alt='doctor desktop'
                        width={`959px`}
                        height={`581px`}
                        />
                    </div>
                </div>

            </section>


            {/*** Nosokomeio Rethumnou  ***/}
            <section className={`row py-5 position-relative sectionContainer position-relative`}>
                <div>
            
                    <img 
                      src='/new/works_page/doctor_assistant/doctor_assistant_bg_image.jpg'
                      alt='doctor assistant bg_image'
                      style={{
                          objectFit: 'cover',
                          height: '70%',
                          position: 'absolute',
                          width: '100%',
                          left: '0',
                          top: '15%',
                          right: '0',
                          bottom: '0',
                          color: 'transparent',
                      }}
                    />
                </div>

                <div className="col-12 col-lg-6">
                    <div className="d-flex justify-content-center align-items-center">
                
                    <img 
                     src='/new/works_page/rethumno/rethumno-desktop.png'
                     alt='nosokomeio rethumnou'
                    width={`959px`}
                    height={`581px`}
                    />
                    </div>
                </div>


                <div className='col-12 col-lg-6 d-flex justify-content-center align-items-center'>

                <div className="position_relative">
                <div className={`bgText`}>

              

                <img 
                src='/new/works_page/rethumno/rethumno-logo.png'
                alt='nosokomeio rethumnou logo'
                className="img-fluid"
                width={`204px`}
                heigth={`207px`}  
                />

                <div>
                <p className={`magentoShop`}>
                      Wep App
                </p>

                <p className={`paragraph`}>
                Ειδικά σχεδιασμένη για την οφθαλμολογική κλινική του νοσοκομείου Ρεθύμνου, η εφαρμογή μας διαχειρίζεται με εξαιρετική ακρίβεια και αποδοτικότητα τους ασθενείς, τα ραντεβού και τα χειρουργεία. Τώρα, οι περίπλοκες διαδικασίες οργάνωσης γίνονται απλές, με μόλις λίγα κλικ.
                </p>

                <p className={`paragraph`}>
                Η εφαρμογή που κατασκευάσαμε είναι όχι μόνο εύχρηστη αλλά και πλήρως προσαρμοσμένη στις ανάγκες των ασθενών και του ιατρικού προσωπικού. Χρησιμοποιώντας την πιο προηγμένη τεχνολογία, επιτυγχάνουμε σημαντική βελτίωση στην ποιότητα της φροντίδας, απλοποιώντας τη διαδικασία επικοινωνίας και συντονισμού. 
                </p>

                <button data-btn={'rethumno'} className={`btn`}>
                  <Link to={`/el/node/132`}>
                  Δείτε το case study
                  </Link>
                  </button>
                </div>

                </div>
                </div>

                </div>

            </section>



            {/*** Contac form  ***/}

            <section className="ls s-pt-30 s-pt-lg-75 s-pb-130 contact4">
              <div className="container">
                <div className="row c-gutter-60">

                  <div className="divider-40 d-none d-xl-block"></div>

                  <div className="col-lg-12">
                    <form className="contact-form c-mb-20 c-gutter-15">

                      <div className="row">

                        <div className="col-sm-12">
                          <h4>Φόρμα επικοινωνίας</h4>
                        </div>
                      </div>

                      <div className="row">

                        <div className="col-sm-12">

                          <div className="form-group has-placeholder name">
                            <label for="name">ΌΝΟΜΑ
                              <span className="required">*</span>
                            </label>
                            <input type="text" aria-required="true" size="30" onBlur={this.handleBlur} onChange={this.handleChange} value={this.state.name} name="name" id="name" className="form-control" placeholder="όνομα"/>

                          </div>
                          <span style={{color: 'red'}} className="errormessage">{this.state.nameerror}</span>
                        </div>


                      </div>

                      <div className="row">

                        <div className="col-sm-12">
                          <div className="form-group has-placeholder email">
                            <label for="email">EMAIL
                              <span className="required">*</span>
                            </label>
                            <input type="email" aria-required="true" size="30" onBlur={this.handleBlur} onChange={this.handleChange} value={this.state.email} name="email" id="email" className="form-control" placeholder="email"/>


                          </div>
                          <span style={{color: 'red'}} className="errormessage">{this.state.emailerror}</span>
                        </div>
                      </div>

                      <div className="row">

                        <div className="col-sm-12">

                          <div className="form-group has-placeholder message">
                            <label for="message">ΜΗΝΥΜΑ</label>
                            <textarea aria-required="true" rows="6" cols="45" onBlur={this.handleBlur} onChange={this.handleChange} value={this.state.message} name="message" id="message" className="form-control" placeholder="μήνυμα"></textarea>

                          </div>
                          <span style={{color: 'red'}} className="errormessage">{this.state.messageerror}</span>
                        </div>

                      </div>

                      <div className="row">
                        <div className="form-group has-placeholder">
                          <button disabled={this.state.buttondisabled} onClick={this.handleSubmit} type="submit" id="contact_form_submit" name="contact_submit" className={"btn btn-block btn-maincolor"}>Αποστολη
                          </button>
                        </div>
                         <div>
                           <CircleSpinner
             size={30}
             color="#686769"
             loading={this.state.loading}
         />
                         </div>



                        {this.state.success? <h2 style={{color:'green'}}>Το μήνυμα εστάλη</h2> : <h2 style={{color:'red'}}>{this.state.errorsend}</h2> }
                      </div>
                    </form>
                  </div>

                  <div className="divider-60 d-none d-xl-block"></div>
                </div>
              </div>
            </section>



          <Footer/>

      </Canvas>



    )
  }

}





export default WorksPage;

export const query = graphql `
{
  allSitePlugin(filter: {name: {eq: "gatsby-source-graphql"}}) {
edges {
node {
  pluginOptions {
    url
  }
  name
}
}
}
  drupal {
  menuel: menuByName(name: "main", language: EL) {
  links {
    label
    url {
      path
    }
  }
}

metainfo:nodeQuery(filter: {conditions: [{field: "title", value: "contact"}]}) {
     entities(language: EL) {
       ... on DRUPAL_NodePage {
         entityLabel
         entityMetatags {
           key
           value
         }
       }
     }
   }
}
}
`
